import { HeadingXl, HeadingLg } from '/features/buildingBlocks/Heading'
import { CtaButtonGhost } from '/features/buildingBlocks/CtaButton'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useSpring, useTrail, animated } from '@react-spring/web'
import { EventCardCompact } from '/features/events/EventCard'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { dayjs } from '/machinery/dayjs'
import { routeMap } from '/routeMap'

import iconArrow from '/images/icons/arrow-right.raw.svg'

import styles from './EventsBooster.css'

const minMax = require('dayjs/plugin/minMax')
dayjs.extend(minMax)

export function EventsBooster({ title, description, events }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [0.5] })
  const language = useLanguage()
  const { __ } = useTranslate()

  const headingSpring = useSpring({
    to: {
      opacity: wasInViewport ? 1 : 0,
      y: wasInViewport ? 0 : 40
    },
    delay: 100,
    config: { tension: 100, friction: 35 }
  })
  const trail = useTrail(events?.length, {
    delay: 200,
    to: {
      opacity: wasInViewport ? 1 : 0,
      y: wasInViewport ? '0%' : '50%',
      scale: wasInViewport ? 1 : 0.9
    },
    config: { tension: 150, friction: 35 },
  })

  return (
    <article className={styles.component}>
      <animated.div style={headingSpring} className={styles.textContainer} ref={elementRef}>
        {
          (title.length < 20)
            ? <HeadingXl h='2'>{title}</HeadingXl>
            : <HeadingLg h='2'>{title}</HeadingLg>
        }
        <p>{description}</p>
        <CtaButtonGhost icon={iconArrow} href={routeMap.app.events({ language })} dataX="link-to-all-events">
          {__`see-all-events`}
        </CtaButtonGhost>
      </animated.div>
      <ol className={styles.eventsContainer}>
        {events?.map((x, i) => (
          <animated.li key={i} style={trail[i]}>
            <EventCardCompact
              key={x._id}
              title={x.title}
              startDate={x.startDate}
              endDate={x.endDate}
              time={x.time}
              location={x.location}
              externalLink={x.externalLink}
            />
          </animated.li>
        ))}
      </ol>
      <div role='presentation' className={styles.backgroundElement} />
    </article>
  )
}
